import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
	BasicStorage,
	ChatMessage,
	ChatProvider,
	IStorage,
	MessageContentType,
	Presence,
	UpdateState,
	User,
	UserStatus,
} from "@chatscope/use-chat";
import { SnikketChatService } from "./SnikketChatService";
import { Chat } from "./components/Chat";
import { nanoid } from "nanoid";
import { AutoDraft } from "@chatscope/use-chat/dist/enums/AutoDraft";
import { useEffect, useState } from "react";
import * as snikket from "snikket-sdk";
import localForage from "localforage";
import * as Sentry from "@sentry/browser";
import GitInfo from "react-git-info/macro";

import { stem } from "porter2";

Sentry.init({
	dsn: "https://ec9ae5aef68e4035937d4c5943c4156c@app.glitchtip.com/7125",

	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration(),
	],

	tracesSampleRate: 0.0,
	tracePropagationTargets: [],

	replaysSessionSampleRate: 0.0,
	replaysOnErrorSampleRate: 1.0,
});

(window as any).snikket = snikket;

// sendMessage and addMessage methods can automagically generate id for messages and groups
// This allows you to omit doing this manually, but you need to provide a message generator
// The message id generator is a function that receives message and returns id for this message
// The group id generator is a function that returns string
const messageIdGenerator = (message: ChatMessage<MessageContentType>) =>
	nanoid();
const groupIdGenerator = () => nanoid();

const storage = new BasicStorage({ groupIdGenerator, messageIdGenerator });

const services = {};
const serviceFactory =
	(xmppClient: snikket.Client) =>
	(storage: IStorage, updateState: UpdateState) => {
		if (services[xmppClient.accountId()]) {
			services[xmppClient.accountId()].storage = storage;
			services[xmppClient.accountId()].updateState = updateState;
			return services[xmppClient.accountId()];
		} else {
			return (services[xmppClient.accountId()] = new SnikketChatService(
				xmppClient,
				storage,
				updateState,
			));
		}
	};

let persistence = null;
localForage.config({ name: "snikket" });
localForage.ready().then(() => {
	persistence = snikket.persistence.browser("snikket", null, stem);
	(window as any).persistence = persistence;
});

// catch it if we're on login screen or something
if (navigator.serviceWorker) {
	navigator.serviceWorker.addEventListener("message", (event) => {
		if (event.data.event === "notificationclick") {
			storage.setActiveConversation(event.data.data.chatId);
		}
	});
}

function App() {
	const state = storage.getState();
	const [xmppClient, setXmppClient] = useState(undefined);
	const [needPassword, setNeedPassword] = useState(false);
	const [accounts, setAccounts] = useState([]);
	const [connectionFailed, setConnectionFailed] = useState(false);

	useEffect(() => {
		(async () => {
			const keys = await localForage.keys();
			const accounts = keys
				.filter((k) => k.startsWith("login:clientId:"))
				.map((k) => k.substring(15));
			setAccounts(
				await Promise.all(
					accounts.map(async (account) => {
						const fn = await localForage.getItem(`fn:${account}`);
						return {
							accountId: account,
							fn: fn,
							hasToken: keys.includes(`login:token:${account}`),
							hasSession: keys.includes(`sm:${account}`),
						};
					}),
				),
			);
		})();
	}, []);

	function login(accountId) {
		const newXmppClient = new snikket.Client(accountId, persistence);
		newXmppClient.addPasswordNeededListener((_) => {
			setNeedPassword(true);
		});
		setXmppClient(newXmppClient);
		storage.addUser(
			new User({
				id: accountId,
				presence: new Presence({
					status: UserStatus.Unavailable,
					description: "",
				}),
				firstName: "",
				lastName: "",
				username: newXmppClient.displayName(),
				email: "",
				avatar: "",
				bio: "",
			}),
		);
		storage.setCurrentUser(storage.getUser(accountId)[0]);

		newXmppClient.addConnectionFailedListener(() => {
			setConnectionFailed(true);
		});

		window.addEventListener("visibilitychange", (event) => {
			if (document.visibilityState === "hidden") {
				newXmppClient.setNotInForeground();
			} else {
				newXmppClient.setInForeground();
			}
		});
	}

	function usePassword(e) {
		xmppClient.usePassword(e.target.password.value);
		setNeedPassword(false);
	}

	if (connectionFailed) {
		return <h1>connection failed, is your server up and supported?</h1>;
	} else if (needPassword) {
		return (
			<>
				<h1>Login</h1>
				<form action="#" onSubmit={usePassword}>
					<input
						name="jid"
						type="text"
						placeholder="Jabber ID"
						value={state.currentUser.id}
						disabled
					/>
					<input name="password" type="password" placeholder="Password" />
					<button>Continue</button>
				</form>
			</>
		);
	} else if (state.currentUser) {
		return (
			<ChatProvider
				serviceFactory={serviceFactory(xmppClient)}
				storage={storage}
				config={{
					typingThrottleTime: 500,
					typingDebounceTime: 30000,
					debounceTyping: true,
					autoDraft: AutoDraft.Save | AutoDraft.Restore,
				}}
			>
				<Chat persistence={persistence} />
			</ChatProvider>
		);
	} else {
		return (
			<>
				<section id="login">
					<h1>Connect</h1>
					<ul>
						{accounts.map((account) => (
							<li onClick={() => login(account.accountId)}>
								{account.fn} (
								{account.accountId +
									(account.hasSession ? ", session" : "") +
									(account.hasToken ? ", token" : "")}
								){" "}
								<button
									onClick={(e) => {
										e.stopPropagation();
										persistence.removeAccount(account.accountId, true);
										setAccounts(
											accounts.filter((a) => a.accountId !== account.accountId),
										);
									}}
								>
									X
								</button>
							</li>
						))}
					</ul>
					{accounts.length > 0 && <p>or other</p>}
					<form
						action="#"
						onSubmit={(e) => {
							e.preventDefault();
							login((e.target as any).jid.value);
						}}
					>
						<input name="jid" type="text" placeholder="Jabber ID" />
						<button>Continue</button>
					</form>
				</section>
				<h1>Snikket SDK Demo App</h1>
				<p>
					This is a demo of the current prototype of{" "}
					<a href="https://snikket.org/blog/state-of-snikket-2023-the-apps/#the-future-of-building-snikket-apps">
						Snikket SDK
					</a>
					. The UI is mostly slapped together in order to be able to demonstrate
					all of the capabilities of the SDK. While it is a working client, it
					is under heavy development with most focus on the SDK and not the app
					itself.
				</p>
				<section>
					<h1>Is this safe?</h1>
					<p>
						This app is highly experimental and under heavy development.
						Nevertheless, at least one person uses it as their full time desktop
						client and has had no bad events so far. Data loss is very unlikely.
						Connections are made via websockets directly to your server with no
						proxy. Since this is not a standard feature of XMPP servers you may
						not be able to use the client because of that, but at least all
						Snikket instances should work. This code is only tested against
						Snikket instances and similarly configured prosody, and may behave
						more erattically in other contexts.
					</p>
					<p>
						This app stores possibly sensitive data, including message history,
						on your computer. Do not use this client from devices you do not
						trust, such as library computers, etc.
					</p>
				</section>
				<section>
					<h1>Is this going to become Snikket Web and/or Cheogram Web?</h1>
					<p>
						Maybe, but that is not the primary focus at this time, the primary
						focus is on SDK developement.
					</p>
				</section>
				<section>
					<h1>Why release this now?</h1>
					<p>
						Because we need testing and feedback about the behaviour in order to
						find bugs and missing features in the SDK.
					</p>
				</section>
				Any and all questions are welcome in the Sopranica discussion room.
				AGPLv3,{" "}
				<a
					href={
						"https://git.singpolyma.net/snikket-react/tree/" +
						GitInfo().commit.hash
					}
				>
					source code
				</a>
			</>
		);
	}
}

export default App;
