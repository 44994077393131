import { Avatar, Status } from "@chatscope/chat-ui-kit-react";
import { Image } from "react-img-placeholder";

export const AvatarWithPlaceholder = ({
	displayName,
	photoUri,
	placeholderUri,
	status,
	...rest
}: {
	displayName: string;
	photoUri: string;
	placeholderUri: string;
	status?: string;
	onClick?: any;
	as?: any;
}) => {
	return (
		<Avatar {...rest}>
			<Image
				{...({} as any)}
				src={photoUri}
				alt={displayName}
				placeholder={<img src={placeholderUri} alt={displayName} />}
			/>
			{typeof status === "string" && (
				<Status status={status as any} size="md" />
			)}
		</Avatar>
	);
};
